import React from 'react'
import icon from '../../assets/img/footer-icon.png'
import history from '../../util/history'
import './index.scss'

export default class footer extends React.Component {
  jumpUrl (url) {
    history.push('/' + url)
  }
  render() {
    return (
      <div id="footer" className="footer">
        <div className='footer-txt'>
          <div className='footer-box'>
             欧信国际控股集团 版权所有
          </div>
          <div className='footer-box'>
            <div>Copynight@2018.All rights reserved | <a href="https://beian.miit.gov.cn/">粤ICP备2021139400号</a></div>
            <img src={icon} alt="" />
          </div>
          
        </div>
      </div>
    )
  }
}