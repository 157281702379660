import React from 'react' 
import style from './index.module.scss'
import history from '@/util/history'


const Comp5 = () => {
  const jumpUrl = () => {
    history.push('/joinus')
  }
  return (
    <div className={style['comp-wrapper']}>
      <div className={style['title']}>加入我们</div>
      <div className={style['hit']}>在欧信，我们倡导本分，分享和共赢</div>
      <div className={style['hit1']}>为员工提供高端医疗保障及家庭式度假旅游；鼓励员工积极健身和不断学习进取。</div>
      <div className={style['btn']} onClick={jumpUrl.bind(this)}>JOIN US</div>
    </div>
  ) 
}

export default Comp5