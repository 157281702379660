import React, { useState, useEffect } from "react";
import style from "./index.module.scss";

export default function ButtonAppBar(props) {
  const { selectIndex, secIndex } = props;

  const [isSecArr, setIsSecArr] = useState(-1);

  const isSecItem = (i) => {
    if(i == isSecArr) {
      return setIsSecArr('-1')
    }
    setIsSecArr(i);
  };

  return (
    <div className={style["bar-list"]}>
      {props.LIST.map((item, i) => (
        <div
          key={i}
          className={`${style[item.urlArr ? "bar-select" : "bar-item"]}`}
        >
          {item.urlArr ? (
            <div className={style["select_con"]}>
              <div className={style["title"]} onClick={isSecItem.bind(this,i)}>
                <div className={style["title_con"]}>{item.str}</div>
                <div
                  className={`${style["title_icon"]} ${
                    isSecArr == '-1' ? style["title_icon2"] : ""
                  }`}
                ></div>
              </div>
              {((selectIndex + '') || '').includes(i+'-') || isSecArr == i ? (
                <div>
                  {item.urlArr.map((sec, index) => (
                    <div
                      className={`${style["secItem"]} ${
                        selectIndex == i+'-'+index ? style["select"] : ""
                      }`}
                      onClick={() => {
                        isSecItem(i+'-'+index)
                        props.handleCli(sec, null, index);
                      }}
                      key={index}
                    >
                      {sec.str}
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div
              className={`${style["firstItem"]} ${
                selectIndex == i ? style["select"] : ""
              }`}
              onClick={() => {
                props.handleCli(item, i, null);
              }}
            >
              {item.str}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
