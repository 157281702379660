import React from 'react' 
import img1 from '@/assets/img/banner_bottom1.png'
import img2 from '@/assets/img/banner_bottom2.png'
import img3 from '@/assets/img/banner_bottom3.png'
import Swiper from '@/compontent/Swiper'
import style from './index.module.scss'


const Comp4 = () => {
  return (
    <div className={style['comp-wrapper']}>
        <div className={style['title']}>愿景</div>
        <div className={style['swiper-wrapper']}>
          <Swiper list={[img1, img2, img3]}/>
        </div>
        <div className={style['hit_']}>致力于用科技金融让客户体验更好的生活。为更多跨国中国企业提供一体化金融科技风控支持。帮助人们追逐自己的需求和梦想，创造更多的可能性。</div>
    </div>
  ) 
}

export default Comp4