import React, {useEffect} from "react";
import style from './index.module.scss'
import { Carousel } from 'antd';
import 'antd/dist/antd.css';
import './index.scss'

const SwiperComp = (props) => {
  const {list, isIndex = false} = props

  return (
    <Carousel autoplay dots={{className: isIndex ? 'slick-dots-swiper-index' : 'slick-dots-swiper'}}>
       {
        list.map((item,i)=>(
          <div key={i}> <img src={item} alt="" /></div>
        ))
      }
    </Carousel>
  );
};

export default SwiperComp;
