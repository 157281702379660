import React, {useState,useEffect} from 'react';
import history from '@/util/history'
import GuideList from '@/compontent/GuideList'
import style from './index.module.scss'


const LIST = [
  {
    str: '首页',
    url: '/',
  },
  {
    str: '关于欧信',
    urlStr: '/about',
    urlArr:[
      {
        str: '集团简介',
        url: '/about?index=0',
      },
      {
        str: '发展历程',
        url: '/about?index=1',
      },
      {
        str: '管理团队',
        url: '/about?index=2',
      },
      {
        str: '集团文化',
        url: '/about?index=3',
      },
    ]
  },
  {
    str: '业务介绍',
    urlStr: '/business',
    urlArr:[
      {
        str: 'OH Credit(分期消费)',
        url: '/business?index=0',
      },
      {
        str: 'OH Pay(先买后付)',
        url: '/business?index=1',
      },
      {
        str: 'OH Cash(个人贷款)',
        url: '/business?index=2',
      },
      {
        str: 'OH Card(虚拟信用卡)',
        url: '/business?index=3',
      },
      {
        str: 'OH AI(智能风控)',
        url: '/business?index=4',
      },
      {
        str: 'OH Tech(金融科技)',
        url: '/business?index=5',
      },
    ]
  },
  {
    str: '加入我们',
    url: '/joinus'
  },
  {
    str: '联系我们',
    url: '/contact'
  }
]

export default function ButtonAppBar(props) {

  const [selectIndex,setSelectIndex] = useState(0)
  const [secIndex,setSecIndex] = useState()
  const [isMenu,setIsMenu] = useState(false)
  useEffect(()=>{
    let index = -1
    for(let i = 0;i<LIST.length;i++){
      let item = LIST[i]
      if(item.url === props.location.pathname){
        index = i
      }else if(item.urlStr === props.location.pathname){
        for(let j = 0;j<item.urlArr.length;j++){
          if(item.urlArr[j].url === props.location.pathname + props.location.search){
            index = i + '-' + j
          }
        }
        
      }
    }

    setSelectIndex(index)
  },[props.location])

  const jumpUrl = (item, i, index,event) => {
    event && event.stopPropagation()
    setSelectIndex(i)
    setSecIndex(index)
    history.push(item.url)
    window.location.reload();
    setIsMenu(false)
  }

  const switchMenu = () => {
    setIsMenu(!isMenu)
  }


  return (
    <>
      {isMenu?<div className={style['cover']} onClick={switchMenu.bind(this)}></div>:''}
      <div className={style['app-bar']}>
        <div className={style['content']}>
          <div className={style['logo']}></div> 
          <div className={`${style['ismenu']} ${style[isMenu ? 'openMenu' : '']}`} onClick={switchMenu.bind(this)}></div>
        </div>
        {
          isMenu ? 
          <GuideList LIST={LIST} handleCli={jumpUrl} selectIndex={selectIndex} secIndex={secIndex}/>
          : ''
        }

      </div>
    </>

  );
}