import React from "react";
import loadable from '../util/loadable.js'
import history from '../util/history'
import { Router, Route, Redirect, Switch } from 'react-router-dom'
import Index from '../view/page1'
import ButtonAppBar from '../compontent/AppBar/index'
import Footer from '../compontent/footer/index'

const routes = [
  {
    path: "/",
    component: Index
  },
  {
    path: "/about",
    component: loadable(() => import (/* webpackChunkName: "page2" */ '@/view/page2'))
  },
  {
    path: "/business",
    component: loadable(() => import (/* webpackChunkName: "page3" */ '@/view/page3'))
  },
  {
    path: "/joinus",
    component: loadable(() => import (/* webpackChunkName: "page4" */ '@/view/page4'))
  },
  {
    path: "/contact",
    component: loadable(() => import (/* webpackChunkName: "page5" */ '@/view/page5'))
  }
];

function routers() {
  return (
    <Router history={history}>
      <Route render={({location})=>{
        return (
          <React.Suspense fallback>
            {/* 导航 */}
            <ButtonAppBar location={location} />

            <Switch>
              {routes.map((route, index) => {
                return <Route key={index} exact path={route.path} component={route.component}/>
              })}
              <Redirect path="/" to="/" />
            </Switch>
            
            <Footer />
          </React.Suspense>
        ) 
      }}></Route>

    </Router>
  );
}

export default routers;